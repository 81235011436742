import React from "react";
import { Row } from "reactstrap";
import { useTranslation } from "react-i18next";

import { Link, useRouteMatch } from "react-router-dom";

import callCenter from "@assets/images/helpdesk_female.png";
import classNames from "classnames";

export default function Footer() {
    const { t } = useTranslation();
    let matchMySelectionsRoute = useRouteMatch("/my-selections");
    let matchMySelectionsListRoute = useRouteMatch("/my-selection-list");
    let matchRegistrationRoute = useRouteMatch("/registration");
    let matchHomeRoute = useRouteMatch("/home");

    return (
        <footer
            className={classNames("footer", {
                "my-selection-footer":
                    matchMySelectionsRoute?.isExact ||
                    matchMySelectionsListRoute?.isExact ||
                    matchRegistrationRoute?.isExact ||
                    matchHomeRoute?.isExact,
                "fixed-bottom":
                    matchHomeRoute?.isExact || matchRegistrationRoute?.isExact,
            })}
            style={{ marginTop: "auto" }}
        >
            <Row className="d-flex col-sm justify-content-center justify-content-sm-start pb-md-row">
                <div className="d-flex">
                    <img
                        src={callCenter}
                        alt=""
                        className="mx-3 mt-3 mt-md-2 call-center-img__clz"
                    />
                    <div className="mt-3 mr-md">
                        <div>{t("Support number")}</div>
                        <div>01 80 81 70 00</div>
                    </div>
                </div>
                <span className="divider d-none d-md-block" />
                <div className="mt-4 mx-1 mb-md-4">
                    © {new Date().getFullYear()} AuresKonnect
                </div>
                <Link to="#" className="mt-4 mx-1 mb-md-4">
                    <u>{t("Home Softavera Analytics")}</u>
                </Link>
                <Link to="#" className="mt-4 mx-1 mb-md-4">
                    <u>{t("Terms of use")}</u>
                </Link>
                <Link to="#" className="mt-4 mx-1 mb-md-4">
                    <u>{t("Confidentiality rule")}</u>
                </Link>
                <Link to="#" className="mt-4 mx-1 mb-md-4">
                    <u>{t("Send a comment")}</u>
                </Link>
            </Row>
        </footer>
    );
}
