import React from "react";
import { Row, CardBody, Card, Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { AvForm } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";
import { BootAlert } from "react-bootalert";
import { useHistory } from "react-router-dom";
import {
    StyledButton,
    StyledLabel,
    StyledTextInput,
} from "@aureskonnect/react-ui";

import { socket } from "@socket";

import { loginUser, apiError, logoutUser } from "@store/actions";

import useFingerprint from "@hooks/useFingerprint";

import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import NProgressWrapper from "@components/Common/NProgressWrapper";
import PageTitle from "@components/Common/PageTitle";
import ForgotPasswordModal from "./ForgotPasswordModal";
import CookiesModal from "./CookiesModal";

import ShowPasswordSvgIcon from "@components/Common/SvgIcons/ShowPasswordSvgIcon";
import HidePasswordSvgIcon from "@components/Common/SvgIcons/HidePasswordSvgIcon";

import { PortalLogoSvgIcon } from "@components/Common/SvgIcons";

import "./Login.css";

export default function Login() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { fingerprint } = useFingerprint();
    const history = useHistory();

    const { error, loading } = useSelector((state: any) => state.Login);

    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isPasswordDisplayed, setIsPasswordDisplayed] = React.useState<
        boolean
    >(false);

    const [isFocusInInputs, setIsFocusInInputs] = React.useState<boolean>(
        false
    );

    function handleValidSubmit({ values }: any) {
        setIsFocusInInputs(false);
        dispatch(apiError(false));
        dispatch(loginUser({ ...values, email: values.email.toLowerCase() }));

        socket.emit("join", values.email.toLowerCase());

        socket.on("disableAccount", () => {
            dispatch(logoutUser(null));
            socket.disconnect();
        });
    }

    function toggleOpeningForgotPasswordModal(event) {
        event?.preventDefault();
        setIsModalOpened(!isModalOpened);
    }

    function handleCreateAccountButtonOnClickEvent() {
        history.push("registration");
    }

    return (
        <React.Fragment>
            <PageTitle title={t("Login")} />
            <video
                autoPlay
                loop
                muted
                style={{
                    zIndex: 0,
                }}
                className="video position-fixed float-right"
            >
                <source src="background.mp4" type="video/mp4" />
            </video>
            <Container fluid={true} className="p-0 m-0 overflow-hidden">
                <NProgressWrapper isLoading={loading} />

                <Row>
                    <div
                        className="white-section"
                        style={{
                            height: "100vh",
                            backgroundColor: "white",
                        }}
                    >
                        <div className="d-flex justify-content-center align-items-center">
                            <PortalLogoSvgIcon
                                fill="black"
                                className="etk-logo__clz"
                            />
                        </div>
                    </div>
                    <Card
                        className="overflow-hidden login-card w-sm-80 w-md-60 w-lg-50 w-xl-40 w-xxl-30"
                        style={{
                            boxShadow: "rgba(0, 0, 0, 0.42) -5px 3px 12px -2px",
                            WebkitBoxShadow:
                                "rgba(0, 0, 0, 0.42) -5px 3px 12px -2px",
                            MozBoxShadow:
                                "rgba(0, 0, 0, 0.42) -5px 3px 12px -2px",
                            borderRadius: "2%",
                            position: "absolute",
                            transform: "translate(-50%, -50%)",
                            padding: "10px",
                            margin: "0px",
                        }}
                    >
                        <p
                            className="mb-2 mt-4 ml-4"
                            style={{
                                fontSize: "33px",
                                fontWeight: 500,
                            }}
                        >
                            {t("Login")}
                        </p>
                        <CardBody className="ml-1 py-0">
                            <div>
                                <AvForm
                                    className="form-horizontal"
                                    onValidSubmit={(_, v: any) => {
                                        handleValidSubmit({ values: v });
                                    }}
                                >
                                    {isFocusInInputs ? null : error ? (
                                        <BootAlert variant="danger">
                                            {t(
                                                "The username or password entered is not correct. Enter it again."
                                            )}
                                        </BootAlert>
                                    ) : null}

                                    <div
                                        style={{
                                            marginBottom: "2rem",
                                        }}
                                    >
                                        <StyledLabel htmlFor="email">
                                            {t("Username")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            id="email"
                                            name="email"
                                            type="email"
                                            className="form-control__clz"
                                            placeholder={t("Username")}
                                            onFocus={() =>
                                                setIsFocusInInputs(true)
                                            }
                                            validate={{
                                                email: {
                                                    value: true,
                                                    errorMessage: t(
                                                        "Please enter a valid username in the format name@example.com"
                                                    ),
                                                },
                                                required: {
                                                    value: true,
                                                    errorMessage: t(
                                                        "This field is required"
                                                    ),
                                                },
                                            }}
                                        />
                                    </div>
                                    <div className="password-toggle__clz">
                                        {isPasswordDisplayed ? (
                                            <HidePasswordSvgIcon
                                                onClick={() =>
                                                    setIsPasswordDisplayed(
                                                        (prevState: boolean) =>
                                                            !prevState
                                                    )
                                                }
                                            />
                                        ) : (
                                            <ShowPasswordSvgIcon
                                                onClick={() =>
                                                    setIsPasswordDisplayed(
                                                        (prevState: boolean) =>
                                                            !prevState
                                                    )
                                                }
                                            />
                                        )}
                                    </div>
                                    <div className="mb-4">
                                        <StyledLabel htmlFor="password">
                                            {t("Password")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            id="password"
                                            name="password"
                                            type={
                                                isPasswordDisplayed
                                                    ? "text"
                                                    : "password"
                                            }
                                            onFocus={() =>
                                                setIsFocusInInputs(true)
                                            }
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: t(
                                                        "This field is required"
                                                    ),
                                                },
                                            }}
                                            placeholder={t("Password")}
                                            className="form-control__clz"
                                        />
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <div className="d-none custom-control custom-checkbox mb-5">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="customControlInline"
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="customControlInline"
                                            >
                                                {t("Remember me")}
                                            </label>
                                        </div>
                                        <StyledButton
                                            //@ts-ignore
                                            variant="link"
                                            className="mb-5"
                                            style={{
                                                textDecoration: "underline",
                                                color: "#495057",
                                                background: "transparent",
                                            }}
                                            onClick={(event) =>
                                                toggleOpeningForgotPasswordModal(
                                                    event
                                                )
                                            }
                                        >
                                            {t("Forgot your password?")}
                                        </StyledButton>
                                    </div>

                                    <div className="d-flex justify-content-center pb-3">
                                        <StyledButton
                                            variant="primary"
                                            rounded={true}
                                            outline={false}
                                            className="text-uppercase"
                                            type="submit"
                                        >
                                            {t("Login")}
                                        </StyledButton>
                                    </div>
                                    <div className="d-none">
                                        <StyledButton
                                            //@ts-ignore
                                            variant="link"
                                            style={{
                                                textDecoration: "underline",
                                                color: "#495057",
                                                background: "transparent",
                                            }}
                                            onClick={
                                                handleCreateAccountButtonOnClickEvent
                                            }
                                        >
                                            {t("Create new account")}
                                        </StyledButton>
                                    </div>
                                </AvForm>
                            </div>
                        </CardBody>
                    </Card>
                </Row>
            </Container>
            <ForgotPasswordModal
                isModalOpened={isModalOpened}
                toggleOpeningForgotPasswordModal={
                    toggleOpeningForgotPasswordModal
                }
            />
            {fingerprint !== "" ? (
                <React.Suspense fallback={null}>
                    <CustomErrorBoundary>
                        <CookiesModal fingerprint={fingerprint} />
                    </CustomErrorBoundary>
                </React.Suspense>
            ) : null}
        </React.Fragment>
    );
}
