import React from "react";
import { Card, CardBody, Nav } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { RootState } from "@store/reducers";
import {
    setAllHistogram,
    setTop10Categories,
    setTop10Histogram,
} from "@pages/Statistics/store";

import { StatisticsContext } from "@pages/Statistics/context";

import useResource from "@hooks/useResource";

import {
    getTypeParam,
    getAdvancedPeriodStringParam,
    getFamiliesFilterQueryString,
    getFormattedCategoriesByPeriod,
    getStatisticsData,
    getSalesSupportQueryString,
    getUntranslatedString,
} from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import ChartWrapper from "@pages/Statistics/CommonCharts/ChartWrapper";

export default function StatisticsServicesCol() {
    const { t } = useTranslation();

    const { period, type, advancedDate, selections } = useSelector(
        (state: RootState) => state
    );

    const { servicesSelection } = React.useContext(StatisticsContext);

    const [categories, setCategories] = React.useState<string[]>([]);
    const [servicesData, setServicesData] = React.useState<any[]>([]);

    const {
        resourceData: statisticsProductsService,
        error: statisticsProductsSalesServiceError,
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/products/service?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }${
            getFamiliesFilterQueryString(servicesSelection!) !== ""
                ? `&service=${getFamiliesFilterQueryString(servicesSelection!)}`
                : ""
        }`
    );

    const {
        resourceData: { data },
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/products/all/services?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }${
            getSalesSupportQueryString(
                servicesSelection!.map(getUntranslatedString)
            ) !== ""
                ? `&sales-support=${getSalesSupportQueryString(
                      servicesSelection!.map(getUntranslatedString)
                  ).toUpperCase()}`
                : ""
        }`
    );

    React.useEffect(() => {
        setCategories(getFormattedCategoriesByPeriod(period, advancedDate));
        setTop10Categories(
            getFormattedCategoriesByPeriod(period, advancedDate),
            "services"
        );
    }, [period, advancedDate]);

    React.useEffect(() => {
        setTop10Histogram(statisticsProductsService.data, "services");
        setServicesData(
            getStatisticsData(
                statisticsProductsService.data,
                categories,
                period,
                advancedDate
            )
        );
    }, [statisticsProductsService, categories, period, advancedDate, t]);

    React.useEffect(() => {
        setAllHistogram(data, "services");
    }, [data]);

    return (
        <Card>
            <CardBody className="card-body__clz card-body__clz-sm card-body__clz-md card-body__clz-lg">
                <Nav
                    pills
                    className="navtab-bg nav__clz nav__clz-sm nav__clz-md nav__clz-lg"
                >
                    <Link
                        style={{ paddingTop: "8px" }}
                        to="/turnover-detail"
                        className="btn btn-outline-dark cards btn-sm float-right d-none"
                    >
                        {t("See more")}{" "}
                        <i className="mdi mdi-arrow-right ml-1"></i>
                    </Link>
                </Nav>
                <div className="pb-0 chart-order chart-order-sm chart-order-md chart-order-lg chart-order-xl">
                    {statisticsProductsSalesServiceError ? (
                        <ErrorPage />
                    ) : (
                        <CustomErrorBoundary>
                            <ChartWrapper
                                series={servicesData}
                                categories={categories}
                            />
                        </CustomErrorBoundary>
                    )}
                </div>
            </CardBody>
        </Card>
    );
}
