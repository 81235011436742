import React from "react";
import { Card, CardBody, Nav, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { RootState } from "@store/reducers";
import {
    setAdvancedDate,
    setAdvancedLegend,
    setAdvancedTypeParam,
    setSegmentQueryString,
} from "@pages/Statistics/Transactions/store";
import { setTop10Categories, setTop10Histogram } from "@pages/Statistics/store";

import { StatisticsContext } from "@pages/Statistics/context";

import useResource from "@hooks/useResource";

import {
    getTypeParam,
    getAdvancedPeriodStringParam,
    getStatisticsData,
    getFormattedCategoriesByPeriod,
    getAdvancedSeeMorePeriod,
} from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import ChartWrapper from "@pages/Statistics/CommonCharts/ChartWrapper";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import "@assets/apex-chart.css";

export default function StatisticsTop10FamiliesCol() {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const { period, type, advancedDate, selections } = useSelector(
        (state: RootState) => state
    );

    const {
        top10FamiliesSelection,
        setTop10FamiliesSelection,
    } = React.useContext(StatisticsContext);

    const [top10FamiliesData, setTop10FamiliesData] = React.useState<any[]>([]);
    const [categories, setCategories] = React.useState<string[]>([]);

    const {
        resourceData: { data: families },
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/products/families-list?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }`
    );

    const {
        resourceData: statisticsFamiliesData,
        error: statisticsFamiliesDataError,
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/products/families?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }${
            top10FamiliesSelection !== ""
                ? `&families=${top10FamiliesSelection}`
                : ""
        }`
    );

    React.useEffect(() => {
        setTop10FamiliesSelection!([...families].join(","));
    }, [families, setTop10FamiliesSelection]);

    React.useEffect(() => {
        setCategories(getFormattedCategoriesByPeriod(period, advancedDate));
        setTop10Categories(
            getFormattedCategoriesByPeriod(period, advancedDate),
            "families"
        );
    }, [period, advancedDate]);

    React.useEffect(() => {
        setTop10Histogram(statisticsFamiliesData.data, "families");
        setTop10FamiliesData(
            getStatisticsData(
                statisticsFamiliesData.data,
                categories,
                period,
                advancedDate
            )
        );
    }, [statisticsFamiliesData, categories, period, advancedDate, t]);

    function handleSeeMoreButtonOnClickEvent() {
        let segmentObject = {
            designation: "temp",
            value: [
                {
                    designation: "articles",
                    value: top10FamiliesData.map((product) => product.name),
                },
                {
                    designation: "families",
                    value: top10FamiliesSelection?.split(","),
                },
            ],
        };

        setAdvancedLegend(t("Products"));
        setAdvancedTypeParam(type);
        setSegmentQueryString(encodeURI(JSON.stringify(segmentObject)));

        setAdvancedDate(getAdvancedSeeMorePeriod(period, advancedDate));

        history.push("/statistics-advanced", { prevUrl: location.pathname });
    }

    return (
        <Card>
            <CardBody className="card-body__clz card-body__clz-sm card-body__clz-md card-body__clz-lg">
                <Nav pills className="navtab-bg float-right d-none">
                    <Button
                        color="secondary"
                        outline
                        size="sm"
                        onClick={handleSeeMoreButtonOnClickEvent}
                    >
                        {t("See more")}{" "}
                        <i className="mdi mdi-arrow-right ml-1"></i>
                    </Button>
                </Nav>
                <div className="apex-chart-padding__clz apex-charts apex-chart__clz">
                    {statisticsFamiliesDataError ? (
                        <ErrorPage />
                    ) : (
                        <CustomErrorBoundary>
                            <ChartWrapper
                                series={top10FamiliesData}
                                categories={categories}
                            />
                        </CustomErrorBoundary>
                    )}
                </div>
            </CardBody>
        </Card>
    );
}
