import React from "react";
import { Table } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import useResource from "@hooks/useResource";

import { TableRow } from "./TableRow";
import ErrorPage from "@components/Common/ErrorPage";

import "../MySelectionsCard/FranchisesTable/index.css";

export default function FranchisesTable() {
    const { t } = useTranslation();

    const [filteredFranchises, setFilteredFranchises] = React.useState<
        FranchisesDataType[]
    >();

    const { resourceData, error } = useResource(
        `${process.env.REACT_APP_API_V1_URL}/selections/franchises-shops-list`
    );

    const [franchises, setFranchises] = React.useState<FranchisesDataType[]>();
    const selections = useSelector(
        (state: { selections: SelectionType }) => state.selections
    );

    React.useEffect(() => {
        setFranchises(resourceData.data);
    }, [resourceData]);

    React.useEffect(() => {
        if (
            franchises &&
            selections.selection !== undefined &&
            selections.selection.length !== 0
        ) {
            let selectedFranchises: FranchisesDataType[] | undefined = [];
            franchises!.forEach((franchise: any) => {
                selections!.selection?.forEach((selection: any) => {
                    if (franchise.id === selection.franchise_id) {
                        let shops = franchise.shops?.filter(function (
                            shop: any
                        ) {
                            return (
                                selection.shops_id.includes(Number(shop.id)) ||
                                selection.shops_id.includes(shop.id)
                            );
                        });

                        let shop = shops.length;

                        selectedFranchises!.push({
                            ...franchise,
                            shops,
                            shop,
                        });
                    }
                });
            });
            setFilteredFranchises!(selectedFranchises);
        } else {
            setFilteredFranchises!(franchises);
        }
    }, [selections, franchises]);

    return (
        <React.Fragment>
            <div className="mt-3 table-responsive fixed-table-head__clz">
                {error ? (
                    <ErrorPage />
                ) : (
                    <Table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>{t("Franchise")}</th>
                                <th>{t("Shop")}</th>
                                <th>{t("Kind")}</th>
                                <th>{t("Address")}</th>
                                <th>{t("Zip code")}</th>
                                <th>{t("City")}</th>
                                <th>{t("Region")}</th>
                                <th>{t("Country")}</th>
                                <th className="text-center">
                                    {t("See the details")}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredFranchises?.map(
                                (franchise: FranchisesDataType) => (
                                    <TableRow
                                        key={`table-row__${franchise.id}`}
                                        franchise={franchise}
                                    />
                                )
                            )}
                        </tbody>
                    </Table>
                )}
            </div>
        </React.Fragment>
    );
}
