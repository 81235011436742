import React from "react";
import {
    Container,
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Avatar from "react-avatar";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { editProfile } from "@store/actions";
import { RootState } from "@store/reducers";

import Breadcrumb from "@components/Common/Breadcrumb";
import PageTitle from "@components/Common/PageTitle";
import SpinnerWrapper from "@components/Common/SpinnerWrapper";

export function UserProfile() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { error, success } = useSelector((state: RootState) => state.Profile);

    const [email, setEmail] = React.useState<string>("");
    const [name, setName] = React.useState<string>("");
    const [idx, setIdx] = React.useState<number>(1);

    React.useEffect(() => {
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser")!);

            setName(`${obj.firstname} ${obj.lastname}`);
            setEmail(obj.email);
            setIdx(obj.id);
        }
    }, []);

    if (error) {
        return (
            <div className="page-content">
                <Container fluid>
                    <Card>
                        <CardBody>
                            <div className="text-center m-5 error__clz">
                                {t("Error getting user info")}
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        );
    }

    return (
        <React.Suspense fallback={<SpinnerWrapper />}>
            <React.Fragment>
                <PageTitle title={t("Profile")} />
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb
                            title={t("Dashboard")}
                            breadcrumbItem={t("Profile")}
                        />
                        <React.Fragment>
                            <Row>
                                <Col lg="12">
                                    {error !== undefined ? (
                                        <Alert color="danger">{error}</Alert>
                                    ) : null}
                                    {success ? (
                                        <Alert color="success">{success}</Alert>
                                    ) : null}

                                    <Card>
                                        <CardBody>
                                            <Media>
                                                <Avatar
                                                    className="mr-2"
                                                    name={name}
                                                    size="55"
                                                    round
                                                />
                                                <Media
                                                    body
                                                    className="align-self-center"
                                                >
                                                    <div className="text-muted">
                                                        <h5>{name}</h5>
                                                        <p className="mb-1">
                                                            {email}
                                                        </p>
                                                    </div>
                                                </Media>
                                            </Media>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            <h4 className="card-title mb-4">
                                {t("Change UserName")}
                            </h4>

                            <Card>
                                <CardBody>
                                    <AvForm
                                        className="form-horizontal"
                                        onValidSubmit={(e: any, v: any) => {
                                            dispatch(editProfile(v));
                                        }}
                                    >
                                        <div className="form-group">
                                            <AvField
                                                name="username"
                                                label={t("UserName")}
                                                value={name}
                                                className="form-control"
                                                placeholder={t(
                                                    "Enter UserName"
                                                )}
                                                type="text"
                                                validate={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: t(
                                                            "This field is required"
                                                        ),
                                                    },
                                                }}
                                            />
                                            <AvField
                                                name="idx"
                                                value={idx}
                                                type="hidden"
                                            />
                                        </div>
                                        <div className="float-right mt-4">
                                            <Button
                                                type="submit"
                                                color="danger"
                                            >
                                                {t("Edit UserName")}
                                            </Button>
                                        </div>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </React.Fragment>
                    </Container>
                </div>
            </React.Fragment>
        </React.Suspense>
    );
}
