import React from "react";
import { Button, Card, CardBody, Nav } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { RootState } from "@store/reducers";
import { setTop10Categories, setTop10Histogram } from "@pages/Statistics/store";

import {
    setAdvancedDate,
    setAdvancedLegend,
    setAdvancedTypeParam,
    setSegmentQueryString,
} from "@pages/Statistics/Transactions/store";

import { StatisticsContext } from "@pages/Statistics/context";

import useResource from "@hooks/useResource";

import {
    getAdvancedPeriodStringParam,
    getAdvancedSeeMorePeriod,
    getFormattedCategoriesByPeriod,
    getStatisticsData,
    getTypeParam,
} from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import ChartWrapper from "@pages/Statistics/CommonCharts/ChartWrapper";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import "@assets/apex-chart.css";

export default function StatisticsTop10SubFamiliesCol() {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const { period, type, advancedDate, selections } = useSelector(
        (state: RootState) => state
    );

    const {
        setCategoriesData,
        top10SubFamiliesSelection,
        setTop10SubFamiliesSelection,
        setHistogramData,
    } = React.useContext(StatisticsContext);

    const [top10SubFamiliesData, setTop10SubFamiliesData] = React.useState<
        any[]
    >([]);
    const [categories, setCategories] = React.useState<string[]>([]);

    const { resourceData: statisticsSubFamiliesList } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/products/sub-families-list?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }`
    );

    const {
        resourceData: statisticsSubFamiliesData,
        error: statisticsSubFamiliesDataError,
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/products/sub-families?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }${
            top10SubFamiliesSelection !== ""
                ? `&sub-families=${top10SubFamiliesSelection}`
                : ""
        }`
    );

    React.useEffect(() => {
        setTop10SubFamiliesSelection!(
            [...statisticsSubFamiliesList.data!].join(",")
        );
    }, [statisticsSubFamiliesList, t, setTop10SubFamiliesSelection]);

    React.useEffect(() => {
        setCategories(getFormattedCategoriesByPeriod(period, advancedDate));
        setTop10Categories(
            getFormattedCategoriesByPeriod(period, advancedDate),
            "subFamilies"
        );
    }, [period, advancedDate, setCategoriesData]);

    React.useEffect(() => {
        setTop10SubFamiliesData(
            getStatisticsData(
                statisticsSubFamiliesData.data,
                categories,
                period,
                advancedDate
            )
        );
        setTop10Histogram(statisticsSubFamiliesData.data, "subFamilies");
    }, [
        statisticsSubFamiliesData,
        categories,
        period,
        advancedDate,
        t,
        setHistogramData,
    ]);

    function handleSeeMoreButtonOnClickEvent() {
        let segmentObject = {
            designation: "temp",
            value: [
                {
                    designation: "articles",
                    value: top10SubFamiliesData.map((product) => product.name),
                },
                {
                    designation: "subfamilies",
                    value: top10SubFamiliesSelection?.split(","),
                },
            ],
        };

        setAdvancedLegend(t("Products"));
        setAdvancedTypeParam(type);
        setSegmentQueryString(encodeURI(JSON.stringify(segmentObject)));

        setAdvancedDate(getAdvancedSeeMorePeriod(period, advancedDate));

        history.push("/statistics-advanced", { prevUrl: location.pathname });
    }

    return (
        <Card>
            <CardBody className="card-body__clz card-body__clz-sm card-body__clz-md card-body__clz-lg">
                <Nav pills className="navtab-bg float-right d-none">
                    <Button
                        color="secondary"
                        outline
                        size="sm"
                        onClick={handleSeeMoreButtonOnClickEvent}
                    >
                        {t("See more")}{" "}
                        <i className="mdi mdi-arrow-right ml-1"></i>
                    </Button>
                </Nav>
                <div className="apex-charts apex-chart__clz">
                    {statisticsSubFamiliesDataError ? (
                        <ErrorPage />
                    ) : (
                        <CustomErrorBoundary>
                            <ChartWrapper
                                series={top10SubFamiliesData}
                                categories={categories}
                            />
                        </CustomErrorBoundary>
                    )}
                </div>
            </CardBody>
        </Card>
    );
}
