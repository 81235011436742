import React from "react";
import { useTranslation } from "react-i18next";
import { DropdownItem } from "reactstrap";
import { useSnapshot } from "valtio";

import store from "@pages/Statistics/store";

import ExportCsvToExcel from "../ExportCsvToExcel";

export function DownloadTop10ExcelDropdownItem({
    filename,
    section,
}: DropdownItemProps) {
    const { t } = useTranslation();

    const snapshot = useSnapshot(store);

    const data = snapshot[section]["top10"].excel;

    return (
        <DropdownItem>
            <ExportCsvToExcel data={data} filename={filename}>
                <div className="btn p-0">
                    {t("Download top 10 in excel format")}
                </div>
            </ExportCsvToExcel>
        </DropdownItem>
    );
}
