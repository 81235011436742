import React from "react";
import { Row } from "reactstrap";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { capitalize } from "@helpers/general";

import PeriodBadgeWrapper from "@components/Common/PeriodBadgeWrapper";

type PropsType = {
    title: string;
} & ChildrenPropsType;

export default function TitleRow({ title, children }: PropsType) {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    let childrenArray = React.Children.toArray(children);

    let newChildren: React.ReactNode;

    let filename = `${t("Statistics")}_${t(
        capitalize(pathname.replace("/", ""))
    )}_${title.replaceAll(" ", "-")}`;

    if (childrenArray.length === 1) {
        newChildren = React.cloneElement(childrenArray[0] as any, {
            filename,
        });
    } else if (childrenArray.length > 1) {
        let lastElement = childrenArray[childrenArray.length - 1];

        newChildren = [React.cloneElement(childrenArray[0] as any)]
            .concat(childrenArray.slice(1, -1) as any)
            .concat(
                React.cloneElement(lastElement as any, {
                    filename,
                }) as any
            );
    }

    return (
        <Row className="justify-content-center justify-content-sm-between rounded mb-2 align-items-center mx-0 dark-bg__clz">
            <div className="d-flex">
                <h4 className="card-title my-2 mx-3 text-white">{title}</h4>
                <span
                    className="badge border text-white my-2 px-3 font-size-10 font-weight-light d-flex align-items-center"
                    style={{ background: "#4D4D4D" }}
                >
                    <PeriodBadgeWrapper />
                </span>
            </div>
            <div className="d-flex">{newChildren}</div>
        </Row>
    );
}
