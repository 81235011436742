import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import useResource from "@hooks/useResource";

import {
    getAdvancedPeriodStringParam,
    formatCurrency,
    formatExcelData,
} from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import ExportCsvToExcel from "../ExportCsvToExcel";

export default function DownloadButton() {
    const { t } = useTranslation();

    const advancedDate: { startDate: string; endDate: string } = useSelector(
        (state: AdvancedDateStateType) => state.advancedDate
    );
    const period: string = useSelector(
        (state: { period: string }) => state.period
    );

    const selections: SelectionType = useSelector(
        (state: { selections: SelectionType }) => state.selections
    );

    const [regulationModes, setRegulationModes] = React.useState<string[]>([]);
    const [vats, setVats] = React.useState<string[]>([]);
    const [vatsRates, setVatsRates] = React.useState<number[]>([]);
    const [excelData, setExcelData] = React.useState<
        {
            columns: any[];
            data: any[];
        }[]
    >([]);

    const {
        resourceData: { data },
        error,
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/dashboard/allStatistics?${getAdvancedPeriodStringParam(
            period,
            advancedDate
        )}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }`
    );

    React.useEffect(() => {
        let localRegulationModes: string[] = [];

        data.forEach((el: any) => {
            localRegulationModes.push(
                ...el.regulationMode.map((datum: any) => t(datum.regulation))
            );
        });

        setRegulationModes(() => {
            return Array.from(new Set(localRegulationModes));
        });
    }, [data, t]);

    React.useEffect(() => {
        data.forEach((datum: any) => {
            datum.vat_table.forEach((el: any) => {
                setVatsRates((prevState: number[]) => {
                    return Array.from(new Set([...prevState, el.vatrate]));
                });

                setVats((prevState: string[]) => {
                    return Array.from(
                        new Set([
                            ...prevState,
                            `${t("Ex-tax VAT")} ${el.vatrate}%`,
                            `${t("VAT")} ${el.vatrate}%`,
                            `${t("Incl-tax VAT")} ${el.vatrate}%`,
                        ])
                    );
                });
            });
        });
    }, [data, t]);

    React.useEffect(() => {
        const DATE_INDEX = 0;
        const ORDER_NUMBER_INDEX = 1;
        const TURNOVER_EX_TAX_INDEX = 2;
        const TURNOVER_INDEX = 3;
        const TOTAL_REGULATION_INDEX = 4;
        const REMAINS_TO_PAY_INDEX = 5;
        const REGULATION_MODES_INDEX = REMAINS_TO_PAY_INDEX + 1;
        const TOTAL_VAT_INDEX = 6 + regulationModes.length;

        let data: any[] = [];
        let sumData: Array<number | string> = [];
        let averageLine: Array<number | string> = [];

        let headers: string[] = [
            t("Date"),
            t("Order count"),
            t("Turnover ex-tax"),
            t("Turnover incl-tax"),
            t("Total Regulation"),
            t("Remains to Pay"),
            ...regulationModes,
            t("Total VAT"),
            ...vats,
        ];

        let indexes: number[] = [];

        vatsRates.forEach((rate) => {
            if (headers.includes(`${t("Ex-tax VAT")} ${rate}%`)) {
                indexes.push(
                    headers.findIndex((header: string) =>
                        header.includes(`${t("Ex-tax VAT")} ${rate}%`)
                    )
                );
            }
        });

        data.forEach((datum: any) => {
            let localArray: any[] = [];
            if (datum.date !== undefined) {
                localArray[DATE_INDEX] = moment(datum.date, "YYYYMMDD").format(
                    "YYYY/MM/DD"
                );
            }

            if (datum.orderNumber !== undefined) {
                localArray[ORDER_NUMBER_INDEX] = datum.orderNumber;
            } else {
                localArray[ORDER_NUMBER_INDEX] = 0;
            }

            if (datum.turnoverExTax !== undefined) {
                localArray[TURNOVER_EX_TAX_INDEX] = datum.turnoverExTax;
            } else {
                localArray[TURNOVER_EX_TAX_INDEX] = 0;
            }

            if (datum.turnover !== undefined) {
                localArray[TURNOVER_INDEX] = datum.turnover;
            } else {
                localArray[TURNOVER_INDEX] = 0;
            }

            if (datum.totalRegulation !== undefined) {
                localArray[TOTAL_REGULATION_INDEX] = datum.totalRegulation;
            } else {
                localArray[TOTAL_REGULATION_INDEX] = 0;
            }

            if (datum.remainsToPay !== undefined) {
                localArray[REMAINS_TO_PAY_INDEX] = datum.remainsToPay;
            } else {
                localArray[REMAINS_TO_PAY_INDEX] = 0;
            }

            if (datum.totalVat !== undefined) {
                localArray[TOTAL_VAT_INDEX] = datum.totalVat;
            } else {
                localArray[TOTAL_VAT_INDEX] = 0;
            }

            regulationModes.forEach((el: string, index: number) => {
                let elementIsInRegulation: boolean = false;

                if (datum!.regulationMode.length > 0) {
                    datum!.regulationMode!.forEach((element: any) => {
                        if (t(element.regulation) === el) {
                            elementIsInRegulation = true;
                            localArray[REGULATION_MODES_INDEX + index] =
                                element.total;
                        }
                    });

                    if (!elementIsInRegulation) {
                        localArray[REGULATION_MODES_INDEX + index] = 0;
                    }
                } else {
                    localArray[REGULATION_MODES_INDEX + index] = 0;
                }
            });

            let vatArrayValues: any[] = Array(vats.length).fill(0);

            datum!.vat_table!.forEach((vat: any) => {
                let rateVatsIndex: number = vats.findIndex((vatEl) =>
                    vatEl.includes(vat!.vatrate)
                );

                vatArrayValues[rateVatsIndex] = vat.totalextaxvat;
                vatArrayValues[rateVatsIndex + 1] = vat.totalvat;
                vatArrayValues[rateVatsIndex + 2] = vat.totalttcvat;
            });

            localArray = localArray.concat(vatArrayValues);
            sumData = Array(localArray.length - 1).fill(0);
            averageLine = Array(localArray.length - 1).fill(0);
            data.push(localArray);
        });

        data?.forEach((datum: number[]) => {
            datum?.slice(1).forEach((el: number, index: number) => {
                sumData[index] = (sumData[index] as number) + (el as number);
            });
        });

        sumData.forEach((el: number | string, index: number) => {
            if (data.length > 0) {
                averageLine[index] =
                    index > 0
                        ? formatCurrency((el as number) / data.length)
                        : ((el as number) / data.length).toFixed(2);
            }
        });

        sumData = sumData.map((el: number | string, index: number) => {
            return index > 0 ? formatCurrency(el as number) : el;
        });

        data = data.map((datum: any) => {
            return datum.map((el: any, index: number) =>
                index > 1 ? formatCurrency(Number(el)) : el
            );
        });

        setExcelData([
            formatExcelData(headers, data, sumData, averageLine, ""),
        ]);
    }, [data, regulationModes, vats, t, vatsRates]);

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <span className="ml-2" style={{ cursor: "pointer" }}>
                    <ExportCsvToExcel data={excelData} filename={"dashboard"}>
                        <i
                            className="bx bx-download"
                            style={{ color: "#495057", fontSize: "20px" }}
                        ></i>
                    </ExportCsvToExcel>
                </span>
            )}
        </React.Fragment>
    );
}
