import { proxy } from "valtio";

type StatisticsStoreType = {
    salesModeCards: CardsModesType;
    salesSupportCards: CardsModesType;
    regulationModesCards: CardsModesType;
    products: StatisticsType;
    salesModes: StatisticsType;
    salesSupport: StatisticsType;
    services: StatisticsType;
    families: StatisticsType;
    subFamilies: StatisticsType;
};

const store = proxy<StatisticsStoreType>({
    salesModeCards: {
        modes: [],
        amounts: [],
    },
    salesSupportCards: {
        modes: [],
        amounts: [],
    },
    regulationModesCards: {
        modes: [],
        amounts: [],
    },
    products: {
        top10: {
            percentage: [],
            categories: [],
            histogram: [],
            csv: [],
            excel: [],
        },
        all: {
            percentage: [],
            categories: [],
            histogram: [],
            csv: [],
            excel: [],
        },
    },
    salesModes: {
        top10: {
            percentage: [],
            categories: [],
            histogram: [],
            csv: [],
            excel: [],
        },
        all: {
            percentage: [],
            categories: [],
            histogram: [],
            csv: [],
            excel: [],
        },
    },
    salesSupport: {
        top10: {
            percentage: [],
            categories: [],
            histogram: [],
            csv: [],
            excel: [],
        },
        all: {
            percentage: [],
            categories: [],
            histogram: [],
            csv: [],
            excel: [],
        },
    },
    services: {
        top10: {
            percentage: [],
            categories: [],
            histogram: [],
            csv: [],
            excel: [],
        },
        all: {
            percentage: [],
            categories: [],
            histogram: [],
            csv: [],
            excel: [],
        },
    },
    families: {
        top10: {
            percentage: [],
            categories: [],
            histogram: [],
            csv: [],
            excel: [],
        },
        all: {
            percentage: [],
            categories: [],
            histogram: [],
            csv: [],
            excel: [],
        },
    },
    subFamilies: {
        top10: {
            percentage: [],
            categories: [],
            histogram: [],
            csv: [],
            excel: [],
        },
        all: {
            percentage: [],
            categories: [],
            histogram: [],
            csv: [],
            excel: [],
        },
    },
});

export function setSalesModeCards(salesModeCards: CardsModesType) {
    store.salesModeCards = salesModeCards;
}

export function setSalesSupportCards(salesSupportCards: CardsModesType) {
    store.salesSupportCards = salesSupportCards;
}

export function setRegulationModesCards(regulationModesCards: CardsModesType) {
    store.regulationModesCards = regulationModesCards;
}

export function setTop10Percentage(data: any[], section: SectionType) {
    store[section].top10.percentage = JSON.parse(JSON.stringify(data));
}

export function setTop10Categories(data: any[], section: SectionType) {
    store[section].top10.categories = JSON.parse(JSON.stringify(data));
}

export function setTop10Histogram(data: any[], section: SectionType) {
    store[section].top10.histogram = JSON.parse(JSON.stringify(data));
}

export function setTop10Csv(data: any[], section: SectionType) {
    store[section].top10.csv = JSON.parse(JSON.stringify(data));
}

export function setTop10Excel(data: any[], section: SectionType) {
    store[section].top10.excel = JSON.parse(JSON.stringify(data));
}

export function setAllPercentage(data: any[], section: SectionType) {
    store[section].all.percentage = JSON.parse(JSON.stringify(data));
}

export function setAllCategories(data: any[], section: SectionType) {
    store[section].all.categories = JSON.parse(JSON.stringify(data));
}

export function setAllHistogram(data: any[], section: SectionType) {
    store[section].all.histogram = JSON.parse(JSON.stringify(data));
}

export function setCsv(data: any[], section: SectionType) {
    store[section].all.csv = JSON.parse(JSON.stringify(data));
}

export function setExcel(data: any[], section: SectionType) {
    store[section].all.excel = JSON.parse(JSON.stringify(data));
}

export default store;
