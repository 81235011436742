import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import i18n from "i18next";

import { StatisticsContext } from "@pages/Statistics/context";

import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import TitleRow from "@components/Common/TitleRow";
import DownloadDropdown from "@components/Common/DownloadDropdown";

import RecapTop10SubFamilies from "./RecapTop10SubFamilies";
import PercentageTop10SubFamilies from "./PercentageTop10SubFamilies";

export default function Top10SubFamilies() {
    const { t } = useTranslation();
    moment.locale(i18n.language);

    const period = useSelector((state: any) => state.period);
    const type = useSelector((state: any) => state.type);
    const advancedDate = useSelector((state: any) => state.advancedDate);
    const selections = useSelector((state: any) => state.selections);

    const [percentageData, setPercentageData] = React.useState<any[]>([]);
    const [histogramData, setHistogramData] = React.useState<any[]>([]);
    const [categoriesData, setCategoriesData] = React.useState<any[]>([]);

    return (
        <React.Fragment>
            <StatisticsContext.Provider
                value={{
                    period,
                    type,
                    advancedDate,
                    selections,
                    percentageData,
                    setPercentageData,
                    histogramData,
                    setHistogramData,
                    categoriesData,
                    setCategoriesData,
                }}
            >
                <TitleRow title={t("Top 10 sub-families")}>
                    <DownloadDropdown />
                </TitleRow>
                <div
                    className="
                d-grid__clz 
                grid-gap__clz 
                grid-xl-statistics-families-and-products-details__clz 
                grid-lg-statistics-template-details__clz 
                grid-md-statistics-template-details__clz
                grid-sm-template-col__clz grid-xs-template-col__clz 
                pr-0"
                >
                    <CustomErrorBoundary>
                        <PercentageTop10SubFamilies />
                    </CustomErrorBoundary>
                    <CustomErrorBoundary>
                        <RecapTop10SubFamilies />
                    </CustomErrorBoundary>
                </div>
            </StatisticsContext.Provider>
        </React.Fragment>
    );
}
