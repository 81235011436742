import React from "react";
import { Card, CardBody, Nav, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import {
    setAdvancedDate,
    setAdvancedLegend,
    setAdvancedTypeParam,
    setSegmentQueryString,
} from "@pages/Statistics/Transactions/store";
import { RootState } from "@store/reducers";
import {
    setAllHistogram,
    setTop10Categories,
    setTop10Histogram,
} from "@pages/Statistics/store";

import { StatisticsContext } from "@pages/Statistics/context";

import useResource from "@hooks/useResource";

import {
    getAdvancedPeriodStringParam,
    getFormattedCategoriesByPeriod,
    getStatisticsData,
    getTypeParam,
    getSalesModesQueryString,
    getUntranslatedString,
    getAdvancedSeeMorePeriod,
} from "@helpers/general";

import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import ErrorPage from "@components/Common/ErrorPage";
import ChartWrapper from "@pages/Statistics/CommonCharts/ChartWrapper";

import "@assets/apex-chart.css";

export default function StatisticsSalesModeCol() {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const { period, type, advancedDate, selections } = useSelector(
        (state: RootState) => state
    );
    const { salesModesSelection } = React.useContext(StatisticsContext);

    const [categories, setCategories] = React.useState<string[]>([]);
    const [salesModesData, setSalesModesData] = React.useState<any[]>([]);

    const {
        resourceData: statisticsSalesModesData,
        error: statisticsSalesModesDataError,
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/products/sales-mode?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }${
            getSalesModesQueryString(salesModesSelection!) !== ""
                ? `&sales-mode=${getSalesModesQueryString(
                      salesModesSelection!.map(getUntranslatedString)
                  )}`
                : ""
        }`
    );

    const {
        resourceData: { data },
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/products/all/sales-mode?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }${
            getSalesModesQueryString(salesModesSelection!) !== ""
                ? `&sales-mode=${getSalesModesQueryString(
                      salesModesSelection!.map(getUntranslatedString)
                  )}`
                : ""
        }`
    );

    React.useEffect(() => {
        setCategories(getFormattedCategoriesByPeriod(period, advancedDate));
        setTop10Categories(
            getFormattedCategoriesByPeriod(period, advancedDate),
            "salesModes"
        );
    }, [period, advancedDate]);

    React.useEffect(() => {
        setTop10Histogram(statisticsSalesModesData.data, "salesModes");
        setSalesModesData(
            getStatisticsData(
                statisticsSalesModesData.data,
                categories,
                period,
                advancedDate
            )
        );
    }, [statisticsSalesModesData, categories, period, advancedDate, t]);

    React.useEffect(() => {
        setAllHistogram(data, "salesModes");
    }, [data]);

    function handleSeeMoreButtonOnClickEvent() {
        let segmentObject = {
            designation: "temp",
            value: [
                {
                    designation: "articles",
                    value: salesModesData.map((product) => product.name),
                },
                {
                    designation: "sales_mode",
                    value: salesModesSelection!
                        .filter((element: string) => {
                            return ![t("All sales methods")].includes(
                                t(element)
                            );
                        })
                        .map(getUntranslatedString),
                },
            ],
        };

        setAdvancedLegend(t("Products"));
        setAdvancedTypeParam(type);
        setSegmentQueryString(encodeURI(JSON.stringify(segmentObject)));

        setAdvancedDate(getAdvancedSeeMorePeriod(period, advancedDate));

        history.push("/statistics-advanced", { prevUrl: location.pathname });
    }

    return (
        <Card>
            <CardBody className="card-body__clz card-body__clz-sm">
                <Nav pills className="navtab-bg float-right d-none">
                    <Button
                        color="secondary"
                        outline
                        size="sm"
                        onClick={handleSeeMoreButtonOnClickEvent}
                    >
                        {t("See more")}{" "}
                        <i className="mdi mdi-arrow-right ml-1"></i>
                    </Button>
                </Nav>
                <CardBody className="p-0 chart-order chart-order-sm">
                    <div className="apex-chart__clz">
                        {statisticsSalesModesDataError ? (
                            <ErrorPage />
                        ) : (
                            <CustomErrorBoundary>
                                <ChartWrapper
                                    series={salesModesData}
                                    categories={categories}
                                />
                            </CustomErrorBoundary>
                        )}
                    </div>
                </CardBody>
            </CardBody>
        </Card>
    );
}
